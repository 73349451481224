<template>
  <div id="positions_module">
    <div class="px-4 py-2 align-center d-flex">
      <div class="font-weight-bold primaryColor" id="positions_module_label">Positions</div>
      <v-progress-circular
        class="ml-2"
        indeterminate
        v-if="postionLoader"
        size="18"
        :width="2"
        color="blue"
      ></v-progress-circular>
    </div>
    <v-row class="mb-2 mx-0 mt-0 px-4">
      <v-slide-group v-model="infoType" class="pa-0" mandatory hide-arrows>
        <v-slide-item
          v-for="(item, i) in tabList"
          :key="i"
          v-bind:value="item.name"
          v-slot="{ active, toggle }"
        >
          <v-tooltip top color="toolTipColor toolTipTop">
            <template v-slot:activator="{ on, attrs }">
              <span
                @click="
                  $router
                    .push({
                      path: 'positions',
                      query: {
                        tab: infoType ? infoType.trim().toString() : 'All',
                      },
                    })
                    .catch(() => {})
                "
              >
                <v-btn
                  depressed
                  v-bind="attrs"
                  v-on="on"
                  :id="`${item.name}_menu`"
                  :color="
                    active
                      ? 'activeColor white--text'
                      : 'unActiveColor black--text'
                  "
                  height="26"
                  min-width="50"
                  class="fsize12 mr-2 rounded-sm text-center text-capitalize"
                  :value="item"
                  @click="toggle"
                >
                  {{ item.name }}
                  <span v-if="item.length != 0">
                    &nbsp; ({{ item.length }})</span
                  >
                </v-btn>
              </span>
            </template>
            <span>{{ item.toolTip }}</span>
          </v-tooltip>
        </v-slide-item>
      </v-slide-group>
      <v-spacer></v-spacer>

     <span
        class="float-right d-flex align-center"
        id="searchBarAndIcons"
        v-if="currentTableData.length > 0 && !$store.state.isMobileView"
      >
        <span class="search-box mx-2" >
          <span class="searchwhole">
           <customIcon  :name="'search'" class="searchimg" :width="'16'" :height="'16'" :color="$store.state.amogaColor"  />
            <span class="searchinput">
              <input id="positionSearch"
                class="placeholdsearch"
                placeholder="Search"
                autocorrect="off"
                v-model="searchPosition"
                oninput="this.value = this.value.toUpperCase()"
              />
            </span>
          </span>
        </span>
        <v-tooltip top color="toolTipColor toolTipTop">
          <template v-slot:activator="{ on, attrs }">
             <span v-bind="attrs" v-on="on" id="positions_down"  @click="checkDownload('postions', 'postions', currentTableData)">
          <customIcon   :name="'download'" class="mx-1 cursor d-flex" :width="'21'" :height="'21'" :color="$store.state.amogaColor"  />
          </span>
          </template>
          <span>Download</span>
        </v-tooltip>
        <v-tooltip top color="toolTipColor toolTipTop">
          <template v-slot:activator="{ on, attrs }">
           <span id="positions_refresh" v-bind="attrs" v-on="on" @click="$store.dispatch('position/getPositions')">
          <customIcon  :name="'refresh'" class="mx-1 cursor d-flex" :width="'16'" :height="'16'" :color="$store.state.amogaColor"  />
          </span>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </span>
    </v-row>
    <v-divider class="mx-4 mb-2"></v-divider>


 <v-card
      class="ma-4 pa-4"
      v-if=" $store.state.isMobileView &&
        (currentTableData.length != 0 &&
          this.searchPosition == '' &&
          !this.postionLoader) ||
        (positionSearchFilter.length != 0 &&
          this.searchPosition !== '' && $store.state.isMobileView &&
          !this.postionLoader)
      "
    >
    <div class="row ma-0 pa-0 d-flex-align-center justify-center fsize14" id="tot_p&l_label"> Total P&L</div>
    <div class="row ma-0 pa-0 d-flex-align-center justify-center fsize14" id="tot_p&l_val" :class="
                  parseFloat(totalGroup(currentTableData)) >= 0
                    ? 'positiveColor'
                    : 'negativeColor'
                "
                >{{ totalGroup(currentTableData) }}</div>
 </v-card>
    <v-card
      class="ma-4"
      v-if="
        (currentTableData.length != 0 &&
          this.searchPosition == '' &&
          !this.postionLoader) ||
        (positionSearchFilter.length != 0 &&
          this.searchPosition !== '' &&
          !this.postionLoader)
      "
    >
      <v-simple-table
        id="positions_table"
        :class="{ overflowinherit: isDropped }"
        v-if="!$store.state.isMobileView"
      >
        <thead id="positions_table_thead">
          <tr class="tableRow" id="positions_table_thead_tr">
            <th class="text-left d-flex align-center" id="positions_table_thead_check_box">
              <input
                type="checkbox"
                v-model="checkboxSelectAll"
                :checkecd="selectedCheckbox.length > 0"
                class="tableCheckBox"
              />
            </th>
            <th class="text-center tableHeader" id="positions_table_thead_type">Type</th>
            <th class="text-left tableHeader" id="positions_table_thead_Inst">Instrument</th>
            <th class="text-right tableHeader" id="positions_table_thead_qty">Qty</th>
            <th class="text-right tableHeader" id="positions_table_thead_buy_avg">Buy Avg</th>
            <th class="text-right tableHeader" id="positions_table_thead_sell_avg">Sell Avg</th>
            <th class="text-right tableHeader" id="positions_table_thead_ltp">LTP</th>
            <th class="text-right tableHeader" id="positions_table_thead_p/l">P/L</th>
          </tr>
        </thead>
        <tbody
          @mouseleave="
            currentIndex = -1;
            isDropped = false;
          " id="positions_table_tbody"
        >
          <tr
            v-for="(item, index) in this.searchPosition == ''
              ? currentTableData
              : positionSearchFilter"
            :key="index"
            class="primaryColor tableRow"
            @mouseenter="
              isDropped = false;
              currentIndex = index;
            "
            :id="`${index}_positions_table_tbody_tr`"
            :class="[item.Netqty == '0' ? 'row-disabled ' : '']"
          >
            <td class="px-4 py-2" style="min-height: 48px" :id="`${index}_positions_table_tbody_check_box`">
              <input
                type="checkbox"
                v-model="selectedCheckbox"
                :value="item"
                :disabled="
                  item.Netqty == '0' ||
                  (item.Netqty !== '0' &&
                    (item.Pcode == 'CO' || item.Pcode == 'BO'))
                "
                class="tableCheckBox"
              />
            </td>
            <td class="text-center px-4 py-2 tableContent" :id="`${index}_positions_table_tbody_prod_type`">
              <button
                class="py-1 px-2 fsize10 rounded-sm"
                :class="
                  item.Pcode.toLowerCase() == 'mis'
                    ? 'misBtn'
                    : item.Pcode.toLowerCase() == 'nrml'
                    ? 'nrmlBtn'
                    : 'cncBtn'
                "
              >
                {{ item.Pcode }}
              </button>
            </td>
            <td class="text-left pos-reletive px-4 py-2 tableContent" :id="`${index}_positions_table_tbody_scrip_name`">
              {{
                item.formatScripName == null ? item.Tsym : item.formatScripName
              }}
              <span class="fsize10 secondaryColor" :id="`${index}_positions_table_tbody_exch`">{{ item.Exchange }}</span>
              <div
                class="dropdown position-absolute right-0"
                v-if="currentIndex == index"
              >
                <v-tooltip top color="toolTipColor toolTipTop">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      id="moreOrderBtn"
                      @mouseover="getWindowHeight()"
                      @click="isDropped = !isDropped"
                      min-width="50"
                      max-width="50"
                      height="23"
                      outlined
                      depressed
                      v-bind="attrs"
                      v-on="on"
                      class="
                        d-flex
                        align-center
                        justify-center
                        pos-reletive
                        fsize12
                        text-capitalize
                        border-0075e1
                        rounded-sm
                        color-0075e1
                        background-white
                      "
                      >More
                    </v-btn>
                  </template>
                  <span>Options</span>
                </v-tooltip>

                <transition name="slide">
                  <ul
                    v-if="currentIndex == index && isDropped"
                    :class="isRotate ? 'bottom25' : ''"
                    class="pa-0 list expectMkWatchMoreList"
                  >
                    <li
                      @click="
                        isDropped = false;
                        callOrderWindow(
                          parseFloat(item.Netqty) < 0 ? 'sell' : 'buy',
                          item,
                          'Add'
                        );
                      "
                      v-if="item.Netqty != '0'"
                      class="primaryColor fsize12 cursor"
                    >
                      Add
                    </li>
                    <li
                      v-if="item.Netqty != '0'"
                      @click="
                        isDropped = false;
                        callOrderWindow(
                          parseFloat(item.Netqty) < 0 ? 'buy' : 'sell',
                          item,
                          'Exit'
                        );
                      "
                      class="primaryColor fsize12 cursor"
                    >
                      Exit
                    </li>
                    <li
                      v-if="item.Netqty == '0'"
                      @click="
                        isDropped = false;
                        callOrderWindow('buy', item, 'Buy');
                      "
                      class="primaryColor fsize12 cursor"
                    >
                      Buy
                    </li>
                    <li
                      @click="
                        isDropped = false;
                        callOrderWindow('sell', item, 'Sell');
                      "
                      v-if="item.Netqty == '0'"
                      class="primaryColor fsize12 cursor"
                    >
                      Sell
                    </li>
                    <li
                      v-if="
                        item.Netqty != '0' &&
                        item.Pcode != 'CO' &&
                        item.Pcode != 'BO'
                      "
                      @click="
                        callConvertDialog(item);
                        isDropped = false;
                      "
                      class="primaryColor fsize12 cursor"
                    >
                      Convert
                    </li>
                    <li
                      @click="
                        showDialog(item);
                        isDropped = false;
                      "
                      class="primaryColor fsize12 cursor"
                    >
                      Info
                    </li>
                  </ul>
                </transition>
              </div>
            </td>
            <td :id="`${index}_positions_table_tbody_qty`"
              class="text-right px-4 py-2 tableContent"
              :class="
                parseInt(item.Netqty) > 0
                  ? 'postiveQuantity'
                  : parseInt(item.Netqty) == 0
                  ? 'zeroQuantity'
                  : 'negativeQuantity'
              "
            >
              {{ item.Netqty }}
            </td>
            <td class="text-right px-4 py-2 tableContent" :id="`${index}_positions_table_tbody_buy_avgprc`">
              {{
                item.Exchange == "CDS" || item.Exchange == "BCD"
                  ? ruppesFormat(parseFloat(item.NetBuyavgprc).toFixed(4))
                  : ruppesFormat(parseFloat(item.NetBuyavgprc).toFixed(2))
              }}
            </td>
            <td class="text-right px-4 py-2 tableContent" :id="`${index}_positions_table_tbody_sell_avg_prc`">
              {{
                item.Exchange == "CDS" || item.Exchange == "BCD"
                  ? ruppesFormat(parseFloat(item.NetSellavgprc).toFixed(4))
                  : ruppesFormat(parseFloat(item.NetSellavgprc).toFixed(2))
              }}
            </td>
            <td class="text-right px-4 py-2 tableContent" :id="`${index}_positions_table_tbody_ltp`">
              {{
                item.Exchange == "CDS" || item.Exchange == "BCD"
                  ? ruppesFormat(parseFloat(item.LTP).toFixed(4))
                  : ruppesFormat(parseFloat(item.LTP).toFixed(2))
              }}
            </td>
            <td :id="`${index}_positions_table_tbody_mtom`"
              class="text-right px-4 py-2 tableContent"
              :class="
                parseFloat(item.MtoM) >= 0 ? 'positiveColor' : 'negativeColor'
              "
            >
              {{ ruppesFormat(parseFloat(item.MtoM).toFixed(2)) }}
            </td>
          </tr>
        </tbody>
        <tfoot id="positions_table_tfoot">
          <tr height="50px" class="primaryColor tableRow" id="positions_table_tfoot_tr">
            <td class="px-4 py-2 tableContent" colspan="3">
              <v-btn id="positions_table_tfoot_exit_btn"
                v-if="selectedCheckbox.length > 0"
                :color="$store.state.buttonThemeColor"
                depressed
                @click="$store.commit('position/setPositionExitDialog', true)"
                min-width="78"
                height="32"
                class="text-capitalize white--text"
                >{{
                  selectedCheckbox.length == 0
                    ? "Exit"
                    : `Exit (${selectedCheckbox.length})`
                }}</v-btn
              >
            </td>
            <td colspan="4" class="text-right px-4 py-2 tableContent" id="positions_table_tfoot_tot_p/l">
              Total P&L
            </td>
            <td colspan="1" class="text-right px-4 py-2 tableContent" id="positions_table_tfoot_tot_p/l_val">
              <span
                :class="
                  parseFloat(totalGroup(currentTableData)) >= 0
                    ? 'positiveColor'
                    : 'negativeColor'
                "
                >{{ totalGroup(currentTableData) }}</span
              >
            </td>
          </tr>
        </tfoot>
      </v-simple-table>

      <div v-if="$store.state.isMobileView">
        <div
          class="row ma-0 px-3 py-2 border-bottom-mwSearch"
          v-for="(item, index) in this.searchPosition == ''
            ? currentTableData
            : positionSearchFilter"
          :key="index"
          :class="parseInt(item.Netqty) == 0 ? 'row-disabled' : '' " @click="callBottomSheet(item)"
          :id="`${index}_positions_div`"
        >
          <div class="row pa-0 ma-0 d-flex align-center justify-space-between w-100">
            <div :id="`${index}_positions_div_qty`"
              class="fsize12"
              :class="
                parseInt(item.Netqty) > 0
                  ? 'postiveQuantity'
                  : parseInt(item.Netqty) == 0
                  ? 'zeroQuantity'
                  : 'negativeQuantity'
              "
            >
              {{ item.Netqty }}
            </div>
            <div class="fsize12" :id="`${index}_positions_sell_avg`">
              Sell Avg.
              {{
                item.Exchange == "CDS" || item.Exchange == "BCD"
                  ? ruppesFormat(parseFloat(item.NetSellavgprc).toFixed(4))
                  : ruppesFormat(parseFloat(item.NetSellavgprc).toFixed(2))
              }}
            </div>
            <div>
              <button :id="`${index}_positions_div_pcode`"
                class="py-1 px-2 fsize10 rounded-sm"
                :class="
                  item.Pcode.toLowerCase() == 'mis'
                    ? 'misBtn'
                    : item.Pcode.toLowerCase() == 'nrml'
                    ? 'nrmlBtn'
                    : 'cncBtn'
                "
              >
                {{ item.Pcode }}
              </button>
            </div>
          </div>
          <div
            class="row my-2 mx-0 pa-0 d-flex align-center justify-space-between w-100"
          >
            <div class="fsize14" :id="`${index}_positions_div_scrip_name`">
              {{
                item.formatScripName == null ? item.Tsym : item.formatScripName
              }}
            </div>
            <div :id="`${index}_positions_div_mtom`"
              class="fsize12"
              :class="
                parseFloat(item.MtoM) >= 0 ? 'positiveColor' : 'negativeColor'
              "
            >
              {{ ruppesFormat(parseFloat(item.MtoM).toFixed(2)) }}
            </div>
          </div>
          <div class="row pa-0 ma-0 d-flex align-center justify-space-between w-100">
            <div class="fsize10 secondaryColor" :id="`${index}_positions_div_exch`">{{ item.Exchange }}</div>
            <div class="fsize12" :id="`${index}_positions_div_buy_avg`">
              Buy Avg.
              {{
                item.Exchange == "CDS" || item.Exchange == "BCD"
                  ? ruppesFormat(parseFloat(item.NetBuyavgprc).toFixed(4))
                  : ruppesFormat(parseFloat(item.NetBuyavgprc).toFixed(2))
              }}
            </div>
            <div class="fsize12" :id="`${index}_positions_div_ltp`">
              LTP
              {{
                item.Exchange == "CDS" || item.Exchange == "BCD"
                  ? ruppesFormat(parseFloat(item.LTP).toFixed(4))
                  : ruppesFormat(parseFloat(item.LTP).toFixed(2))
              }}
            </div>
          </div>
        </div>
      </div>
    </v-card>

    <div
      class="d-flex flex-column justify-center align-center vh-70"
      v-if="
        (!!currentTableData &&
          currentTableData.length == 0 &&
          this.searchPosition == '' &&
          !this.postionLoader) ||
        (!!currentTableData &&
          positionSearchFilter.length == 0 &&
          this.searchPosition !== '' &&
          !this.postionLoader)
      "
    >
      <div>
        <img id="positions_no_data_img"
          src="../assets/images/noData.svg"
          alt=""
          class="w-300 h-170 mb-4"
        />
      </div>
      <div class="primaryColor" id="positions_no_data_label">NO POSITIONS FOUND</div>
    </div>

    <!-- info dialog -->

    <v-dialog
      v-model="infoDialog"
      width="650px"
      transition="scroll-y-reverse-transition"
    >
      <v-card class="pa-5" id="positions_info_card">
        <div class="d-flex align-baseline py-3 border-bottom-dark">
          <button id="positions_info_pcode"
            class="rounded-sm py-1 px-2 text-capitalize fsize10"
            v-bind:class="[
              this.postionInfodetails.Pcode == 'NRML' ? 'nrmlBtn' : 'misBtn ',
            ]"
          >
            {{ this.postionInfodetails.Pcode }}
          </button>
          <div class="mx-2 fsize14" id="positions_info_symbol">
            {{
              this.postionInfodetails.InsdisplayName != null
                ? this.postionInfodetails.InsdisplayName
                : this.postionInfodetails.Tsym
            }}
          </div>
          <div class="fsize9" id="positions_info_exch">{{ this.postionInfodetails["Exchange"] }}</div>
        </div>
        <div class="py-2 border-bottom-dark">
          <div class="row ma-0">
            <div class="col-6 py-4 pr-2 pl-0 border-right">
              <div class="d-flex align-center justify-space-between">
                <div class="fsize12 secondaryColor mb-2" id="positions_info_qty">Quantity</div>
                <div class="fsize12 primaryColor mb-2" id="positions_info_qty_val">
                  {{ this.postionInfodetails["Netqty"] }}
                </div>
              </div>
              <div class="d-flex align-center justify-space-between">
                <div class="fsize12 secondaryColor mb-2" id="positions_info_avg_prc_label">Avg.price</div>
                <div class="fsize12 primaryColor mb-2" id="positions_info_avg_prc_val">
                  {{ this.postionInfodetails["netbuyamt"] }}
                </div>
              </div>
              <div class="d-flex align-center justify-space-between">
                <div class="fsize12 secondaryColor mb-2" id="positions_info_ltp_label">Last Traded Price</div>
                <div class="fsize12 primaryColor mb-2" id="positions_info_ltp_val">
                  {{ this.postionInfodetails["LTP"] }}
                </div>
              </div>
              <div class="d-flex align-center justify-space-between">
                <div class="fsize12 secondaryColor mb-2" id="positions_info_order_type_label">Order type</div>
                <div class="fsize12 primaryColor mb-2" id="positions_info_order_type_val">Day</div>
              </div>
              <div class="d-flex align-center justify-space-between">
                <div class="fsize12 secondaryColor mb-2" id="positions_info_product_label">Product</div>
                <div class="fsize12 primaryColor mb-2" id="positions_info_product_val">
                  {{ this.postionInfodetails["Pcode"] }}
                </div>
              </div>
              <div class="d-flex align-center justify-space-between">
                <div class="fsize12 secondaryColor mb-2" id="positions_info_valitity_label">Valitity</div>
                <div class="fsize12 primaryColor mb-2" id="positions_info_valitity_val">
                  {{ this.postionInfodetails["Expdate"] }}
                </div>
              </div>
            </div>
            <div class="col-6 py-4 pr-2 pl-0">
              <div class="py-1 px-4">
                <div class="pl-4 fsize14 primaryColor mb-2" id="positions_info_buys">Buys</div>
                <div class="d-flex justify-space-between">
                  <div class="pl-4 fsize12 secondaryColor mb-2" id="positions_info_buys_qty_label">Qty</div>
                  <div class="width-30 fsize12 primaryColor mb-2" id="positions_info_buys_qty_val">
                    {{ this.postionInfodetails["Bqty"] }}
                  </div>
                </div>
                <div class="d-flex justify-space-between">
                  <div class="pl-4 fsize12 secondaryColor mb-2" id="positions_info_buys_prc_label">Price</div>
                  <div class="width-30 fsize12 primaryColor mb-2" id="positions_info_buys_prc_val">
                    {{ this.postionInfodetails["NetBuyavgprc"] }}
                  </div>
                </div>

                <div class="d-flex justify-space-between">
                  <div class="pl-4 fsize12 secondaryColor mb-2" id="positions_info_buys_value_label">Value</div>
                  <div class="width-30 fsize12 primaryColor mb-2" id="positions_info_buys_value">
                    {{ this.postionInfodetails["netbuyamt"] }}
                  </div>
                </div>

                <hr class="my-2" />
                <div class="pl-4 fsize14 primaryColor mb-2" id="positions_info_sells">Sells</div>
                <div class="d-flex justify-space-between">
                  <div class="pl-4 fsize12 secondaryColor mb-2" id="positions_info_sells_qty">Qty</div>
                  <div class="width-30 fsize12 primaryColor mb-2" id="positions_info_sells_qty_val">
                    {{ this.postionInfodetails["Sqty"] }}
                  </div>
                </div>
                <div class="d-flex justify-space-between">
                  <div class="pl-4 fsize12 secondaryColor mb-2" id="positions_info_sells_prc_label">Price</div>
                  <div class="width-30 fsize12 primaryColor mb-2" id="positions_info_sells_prc_VAL">
                    {{ this.postionInfodetails["NetSellavgprc"] }}
                  </div>
                </div>

                <div class="d-flex justify-space-between">
                  <div class="pl-4 fsize12 secondaryColor mb-2" id="positions_info_sells_VALUE_LABEL">Value</div>
                  <div class="width-30 fsize12 primaryColor mb-2" id="positions_info_sells_VALUE">
                    {{ this.postionInfodetails["netSellamt"] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-4 ma-0 d-flex justify-end">
          <v-btn id="positions_close_btn"
            depressed
            outlined
            class="fsize14 text-capitalize"
            width="60px"
            height="32px"
            @click="infoDialog = false"
            >Close</v-btn
          >
        </div>
      </v-card>
    </v-dialog>

    <!-- exit dialog popup -->
    <v-dialog v-model="exitPositionDialog" max-width="700px">
      <v-expand-transition>
        <v-card class="pa-5" id="exitPositions_card">
          <div class="pb-4">
            {{
              this.selectedCheckbox.length > 1
                ? "Exit Positions"
                : "Exit Position"
            }}
          </div>
          <div class="">
            <v-simple-table class="border-ededed" id="exitPositions_table">
              <thead id="exitPositions_table_thead">
                <tr id="exitPositions_table_thead_tr">
                  <th id="exitPositions_table_thead_th"></th>
                  <th id="exitPositions_table_thead_Inst" class="text-left"><span>Instrument</span></th>
                  <th id="exitPositions_table_thead_qty" class="text-right"><span>Qty</span></th>
                  <th id="exitPositions_table_thead_price" class="text-center"><span>Price</span></th>
                  <th id="exitPositions_table_thead_type" class="text-left"><span>Type</span></th>
                  <th id="exitPositions_table_thead_prod" class="text-left"><span>Product</span></th>
                  <th id="exitPositions_table_thead_valitity" class="text-left"><span>Validity</span></th>
                </tr>
              </thead>
              <tbody id="exitPositions_table_tbody">
                <tr v-for="(item, index) in this.selectedCheckbox" :key="index" :id="`${index}_exitPositions_table_tbody_tr`">
                  <td class="text-center">
                    <button :id="`${index}_exitPositions_table_tbody_qty`"
                      class="rounded-sm text-capitalize fsize10 px-3 py-1"
                      :class="item.Netqty > 0 ? 'sellcolor' : 'buycolor'"
                    >
                      {{ item.Netqty > 0 ? "SELL" : "BUY" }}
                    </button>
                  </td>

                  <td class="text-left" :id="`${index}_exitPositions_table_tbody_scrip_name`">
                    {{
                      item.formatScripName == null
                        ? item.Tsym
                        : item.formatScripName
                    }}
                    <span class="fsize10 secondaryColor" :id="`${index}_exitPositions_table_tbody_exch`">{{
                      item.Exchange
                    }}</span>
                  </td>

                  <td class="text-right" :id="`${index}_exitPositions_table_tbody_qty`">
                    <span>{{ Math.abs(item.Netqty) }}</span>
                  </td>

                  <td class="text-center" :id="`${index}_exitPositions_table_tbody_dash`">
                    <span>--</span>
                  </td>

                  <td class="text-left" :id="`${index}_exitPositions_table_tbody_mkt`">
                    <span>MKT</span>
                  </td>

                  <td class="text-left" :id="`${index}_exitPositions_table_tbody_pcode`">
                    <span>{{ item.Pcode }}</span>
                  </td>
                  <td class="text-left" :id="`${index}_exitPositions_table_tbody_day`">
                    <span>DAY</span>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <div class="text-right pt-4">
            <v-btn id="exit_position_confirm_btn"
              height="32"
              min-width="80"
              depressed
              :loading="exitLoader"
              :disabled="exitLoader"
              :color="$store.state.buttonThemeColor"
              class="fsize14 text-capitalize white--text"
              @click="exitAllPositions()"
              >Confirm</v-btn
            >
            <v-btn id="exit_position_close_btn"
              height="32"
              outlined
              min-width="80"
              depressed
              class="ml-2 secondaryColor fsize14 text-capitalize"
              @click="$store.commit('position/setPositionExitDialog', false)"
            >
              Close
            </v-btn>
          </div>
        </v-card>
      </v-expand-transition>
    </v-dialog>

    <!-- convert dialog box -->
    <v-dialog v-model="positionConvertDialog" width="500px">
      <v-expand-transition>
        <v-card id="convert_dialog_card">
          <div
            class="
              d-flex
              align-center
              justify-space-between
              py-4
              px-4
              border-bottom-dark
            "
          >
            <span class="fsize16 font-weight-bold" id="convert_dialog_card_label">Convert Order</span>
            <span>
              <span class="fsize14 pr-2" id="convert_dialog_card_scrip_name"
                >{{
                  convertDialogData.formatScripName == null
                    ? convertDialogData.Trsym
                    : convertDialogData.formatScripName
                }} </span
              ><span class="secondaryColor fsize13" id="convert_dialog_card_scrip_qty"
                >Max Qty: {{ convertDialogData.Netqty }}</span
              >
            </span>
          </div>
          <div class="pa-3">
            <v-sheet class="d-flex align-center justify-space-between my-2">
              <div class="d-flex flex-column align-center">
                <p class="secondaryColor fsize12 mb-1" id="convert_dialog_card_scrip_type">Type</p>
                <p class="primaryColor fsize14 mb-0" id="convert_dialog_card_scrip_type_val">DAY</p>
              </div>
              <div class="d-flex flex-column">
                <p class="secondaryColor mb-1" id="convert_dialog_card_scrip_prod">Product</p>
                <p class="primaryColor fsize14 mb-0" id="convert_dialog_card_scrip_prod_val">
                  <span
                    :class="
                      convertDialogData.Pcode == 'MIS'
                        ? 'misBtn  py-1 px-2 fsize10 rounded-sm'
                        : convertDialogData.Pcode == 'NRML'
                        ? 'nrmlBtn  py-1 px-2 fsize10 rounded-sm'
                        : ''
                    "
                  >
                    {{ convertDialogData["Pcode"] }}</span
                  ><span class="px-2">
                    <v-icon>mdi-arrow-right-bold</v-icon>
                  </span>
                  <span id="convert_dialog_card_scrip_prod_convert"
                    :class="
                      convertDialogData.Pcode == 'MIS'
                        ? 'nrmlBtn  py-1 px-2 fsize10 rounded-sm'
                        : convertDialogData.Pcode == 'NRML'
                        ? 'misBtn  py-1 px-2 fsize10 rounded-sm'
                        : ''
                    "
                    >{{
                      convertDialogData["Pcode"] == "NRML" ||
                      convertDialogData["Pcode"] == "CNC"
                        ? "MIS"
                        : convertDialogData["Exchangeseg"] == "nse_cm" ||
                          convertDialogData["Exchangeseg"] == "bse_cm"
                        ? "CNC"
                        : "NRML"
                    }}</span
                  >
                </p>
              </div>
              <div class="d-flex flex-column">
                <p class="secondaryColor fsize12 mb-1" id="convert_dialog_card_scrip_qty_label">Quantity</p>
                <v-text-field
                  height="40"  id="convert_dialog_card_scrip_qty_inp"
                  @input="qtyErr = inputFieldValidator()"
                  :step="Math.abs(minlot)"
                  :min="0"
                  :max="convertDialogData.Netqty"
                  class="body-2 qtyInput"
                  v-model.number="convertQty"
                  outlined
                  type="number"
                  autofocus
                  hide-no-data
                  hide-details
                  solo
                  tile
                  flat
                  dense
                ></v-text-field>
              </div>
            </v-sheet>
            <div class="d-flex justify-space-between pt-4">
              <div class="fsize12 red--text" id="convert_dialog_card_scrip_qty_err_msg">{{ qtyErr }}</div>
              <div>
                <v-btn id="convert_dialog_card_confirm_btn"
                  height="32"
                  :loading="convertionLoader"
                  :disabled="convertionLoader"
                  min-width="80"
                  depressed
                  :color="$store.state.buttonThemeColor"
                  class="fsize14 text-capitalize white--text"
                  @click="positionConvert()"
                  >Convert</v-btn
                >
                <v-btn id="convert_dialog_card_close_btn"
                  height="32"
                  min-width="80"
                  outlined
                  depressed
                  class="ml-2 fsize14 text-capitalize"
                  @click="
                    $store.commit('position/setPositionConvertDialog', false)
                  "
                >
                  Close
                </v-btn>
              </div>
            </div>
          </div>
        </v-card>
      </v-expand-transition>
    </v-dialog>

    <bottomsheet :currentData="currentSheetData" v-on:from-child ="getChildData"/>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import commonFunc from "../mixins/commonFunctions";
import orderWindowjs from "../mixins/orderWindow";
import toaster from "../store/Services/errorHandling";
import bottomsheet from '../components/bottomSheet.vue'
import customIcon from '../components/customIcon.vue'
export default {
  mixins: [commonFunc, orderWindowjs],
  data: () => ({
    searchPosition: "",
    infoDialog: false,
    selectedCheckbox: [],
    postionInfodetails: [],
    convertDialog: false,
    isDropped: false,
    currentIndex: -1,
    convertDialogData: [],
    convertQty: Number,
    qtyErr: "",
    isRotate: true,
    currentSheetData: []
  }),
  components:{
    bottomsheet,customIcon
  },

  computed: {
    ...mapState("position", [
      "totalPnl",
      "currentTableData",
      "tabList",
      "postionLoader",
      "positionConvertData",
      "exitLoader",
      "convertionLoader",
      "currentTotalPnl",
    ]),
    ...mapState("orderWindow", ["minlot"]),
    ...mapGetters("position", [
      "getPositionExitDialog",
      "getPositionConvertDialog",
    ]),

    positionSearchFilter() {
      return this.currentTableData.filter((post) => {
        return post.Tsym && post.Tsym.toLowerCase().includes(
          this.searchPosition.toLowerCase()
        );
      });
    },

    checkboxSelectAll: {
      get: function () {
        var _checked = [];
        this.currentTableData.forEach((item) => {
          if (
            item.Netqty != "0" &&
            item.Pcode !== "CO" &&
            item.Pcode !== "BO"
          ) {
            _checked.push(item);
          }
        });
        return this.currentTableData && _checked.length > 0
          ? this.selectedCheckbox.length == _checked.length
          : false;
      },
      set: function (value) {
        var checked = [];
        if (value) {
          this.currentTableData.forEach((item) => {
            if (
              item.Netqty != "0" &&
              item.Pcode !== "CO" &&
              item.Pcode !== "BO"
            ) {
              checked.push(item);
            }
          });
        }
        this.selectedCheckbox = checked;
      },
    },

    exitPositionDialog: {
      get() {
        return this.getPositionExitDialog;
      },
      set(value) {
        if (!value) {
          this.$store.commit("position/setPositionExitDialog", value);
          return value;
        }
      },
    },

    positionConvertDialog: {
      get() {
        return this.getPositionConvertDialog;
      },
      set(value) {
        if (!value) {
          this.$store.commit("position/setPositionConvertDialog", value);
          return value;
        }
      },
    },

    infoType: {
      get() {
        return this.$store.state.position.infoType;
      },
      set(value) {
        this.$store.commit("position/setCurrentTab", value);
        this.$store.commit("position/setCurrentTableData", value);
        this.selectedCheckbox = [];
        localStorage.setItem("positionTab", JSON.stringify(value));
      },
    },
  },
  created() {
    this.$store.dispatch("position/getPositions");
    this.selectedCheckbox = [];
  },

  methods: {
     getChildData(data){
      if(data['page'] == 'Position'){
        if(data['action'] == 'buy' || data['action'] == 'sell' || data['action'] == 'Add' || data['action'] == 'Exit'){
       data['action'] == 'Add' ? this.callOrderWindow(parseFloat(data['item'].Netqty) < 0 ? 'sell' : 'buy' ,data.item, data['action']) : data['action'] == 'Exit' ? this.callOrderWindow(parseFloat(item.Netqty) < 0 ? 'buy' : 'sell', item, data['action']) :  this.callOrderWindow(data['action'] , data.item , data['action'] == 'buy' ? 'Buy' : 'Sell')
        }
         if(data['action'] == "Convert"){
          this.callConvertDialog(data.item)
        }
         if(data['action'] == 'info'){
          this.showDialog(data.item)
        }
         if(data['action'] == 'squareOff'){
          this.selectedCheckbox.push(data['item'])
          this.exitAllPositions()
        }
      }
    },
    callBottomSheet(val){
         this.currentSheetData = []
      var tempData = {
        where: 'Position',
        isOpen: true,
        item: val,
      }
      this.currentSheetData.push(tempData)
    },
    getWindowHeight() {
      var offsetHeight =
        this.$store.state.windowHeight -
        document.getElementById("moreOrderBtn").getBoundingClientRect().top;
      var dropdownHeight = 125;
      offsetHeight < dropdownHeight
        ? (this.isRotate = true)
        : (this.isRotate = false);
    },
    showDialog(item) {
      this.postionInfodetails = item;
      this.infoDialog = true;
    },
    // call order window
    async callOrderWindow(orderType, value, type) {
      if (type == "Exit" && (value.Pcode == "CO" || value.Pcode == "BO")) {
        toaster.toaster(
          "",
          "primary",
          "Exit BO and CO Order From OrderBook",
          5000
        );
        this.$store.commit(
          "header/setTabSelection",
          this.$store.state.isMobileView ? 2 : 1
        );
        this.$router
          .push({ path: "orders", query: { tab: "Pending" } })
          .catch(() => {});
      } else {
        this.$store.commit("orderWindow/setWindowLoading", true);
        this.$store.dispatch("orderWindow/setInitialValues", {
          data: value,
          page: "positions",
        });
        orderType == "buy" ? false : true;
        this.$store.commit("orderWindow/setCurrentOrder", {
          data: value,
          page: "positions",
        });
        this.$store.commit("orderWindow/setOrderWindow", orderType);
        this.$store.commit("orderWindow/setOrderType", orderType);
        this.changeOrderTab();
      }
    },

    async callConvertDialog(value) {
      this.$store.commit("orderWindow/setCurrentOrder", {
        data: value,
        page: "positions",
      });
      await this.$store.dispatch("orderWindow/getScripQuoteDetails", value);
      this.$store.commit("position/setPositionConvertDialog", await true);
      this.convertDialogData = value;
      this.convertQty = Math.abs(this.convertDialogData.Netqty);
    },

    async exitAllPositions() {
      await this.$store.dispatch(
        "position/exitPosition",
        this.selectedCheckbox
      );
      this.selectedCheckbox = [];
    },

    inputFieldValidator() {
      if (this.convertQty.toString() == "") {
        return "Quantity cannot not be empty";
      } else if (this.convertQty == 0) {
        return "Quantity should not be Zero";
      } else if (this.convertQty % this.minlot != 0) {
        return "Quantity should be multiple of " + this.minlot;
      } else if (this.convertQty > this.convertDialogData.Netqty) {
        return (
          "Quantity should not greater then " + this.convertDialogData.Netqty
        );
      }else{
        return null
      }
    },

    /**
     * Covert Position
     * @author senthil
     * @since 13/12/21
     */
    async positionConvert() {
      if (!this.qtyErr) {
        await this.$store.dispatch("position/positionConversion", {
          data: this.convertDialogData,
          qty: this.convertQty,
        });
      }
    },
  },

  mounted() {
    var localTab = JSON.parse(localStorage.getItem("positionTab"));
    this.$route.query.tab
      ? (this.infoType = this.$route.query.tab)
      : (this.infoType = localTab);
    // this.$store.commit("position/setCurrentTab", this.infoType);
    // this.$store.commit("position/setCurrentTableData", this.infoType);
  },
};
</script>